import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className='footer-container'>
          <small className='website-rights' >Mo Mo Cha © 2022</small>
    </div>
  );
}

export default Footer;
